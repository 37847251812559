<template>
  <div class="vipPopup">
    <van-overlay :show="show" @click="close(0)">
      <!-- VIP 弹窗样式1 -->
      <div class="vipContent1" v-if="state == 1">
        <div>
          <div class="bgImg">
            <div class="openBtn" @click="openVip">
              <!-- <img @click="openVip" src="@/assets/png/vipPopOpen.png" /> -->
              <div class="vipTitle">温馨提示</div>
            </div>
            <div class="vipNum">
              <span>您还不是会员&nbsp;无法继续</span>
            </div>
            <div class="Continue">
              <span>开通会员&nbsp;即可解锁继续查看</span>
            </div>
            <div class="vipBtn">
              <div class="textBtn" @click="vipClose">
                <span>邀请好友</span>
              </div>
              <div class="openBtn" @click="openVip">
                <span>开通会员</span>
              </div>
            </div>
            <div class="ticket" @click.stop="volumeUnlock" v-if="couponNum">
              <span>使用观阅券({{ (couponNum || 0) | watchCount }})</span>
            </div>
          </div>
          <!-- <div class="closeBtn" @click="close">
            <img src="@/assets/png/vipPopBtn.png" />
          </div> -->
        </div>
      </div>
      <!-- 金豆购买 弹窗样式1 -->
      <div class="vipContent3" v-if="state == 2">
        <div>
          <div class="bgImg">
            <div class="unlockGolden">
              <!-- <img @click="openGolden" src="@/assets/png/unlockGolden.png" /> -->
              <div class="goldTitle">金币解锁</div>
            </div>
            <div class="goldenNum">
              <span>您只需花费&nbsp;<span class="golds">{{ goldenNum }}</span>&nbsp;金币</span>
            </div>
            <div class="Continue">
              <span>即可解锁继续查看</span>
            </div>
            <div class="goldBtn">
              <div class="textBtn" @click="close(1)">
                <span>稍后再说</span>
              </div>
              <div class="openBtn">
              <!-- <img @click="openGolden" src="@/assets/png/goldenOpen.png" /> -->
                <span @click="openGolden">立即解锁</span>
              </div>
            </div>
            <div class="ticket" @click.stop="volumeUnlock" v-if="couponNum">
              <span>使用观阅券({{ (couponNum || 0) | watchCount }})</span>
            </div>
          </div>
          <!-- <div class="closeBtn" @click="close">
            <img src="@/assets/png/vipPopBtn.png" />
          </div> -->
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { queryRights } from "@/api/user";
import { goldExchange } from "@/api/mine";
import { Toast } from "vant";
import {mapGetters} from "vuex";

let _this = null;

export default {
  name: "VipPopup",
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    state: {
      type: Number,
      default: 1,
    },
    goldenNum: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
    },
    videoType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      couponNum: 0, // 观阅券数量
      couponList: [],
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
    }),
  },
  mounted() {
    window.addEventListener('popstate', this.handlePopstate);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handlePopstate);
  },
  created() {
    // console.log(23123123)
    this.queryRights();
    _this = this;
  },
  methods: {
    // 监听 popstate 事件
    handlePopstate() {
      if (!this.$emit("close")) {
        this.close(0);
      }
    },
    vipClose() {
      this.$emit("close");
      this.$router.push('sharePromotion')
    },
    close(type) {
      if(type == 0){
        if(this.state == 1){
          this.$emit("close");
        }
      }else{
        this.$emit("close");
      }
    },
    openVip() {
      this.$emit("openVip");
      this.$router.push("/rechargePage?t=vip");
    },
    // 查询权益
    async queryRights() {
      let res = await this.$Api(queryRights);
      if (res && res.code == 200) {
        this.couponList = res.data.watchReadCoupon || [];
        this.couponNum = this.couponList.length;
        // console.log(couponList)
      }
    },
    async buySuccess(){
      this.$emit("buySuccess");
    },
    async openGolden() {
      /**
       * 金币兑换作品
       */
      let req = {
        productType: this.videoType,
        productID: this.id,
        isH5: true,
      };
      _this.$emit("openGolden");
      try {
        await this.$store.dispatch("user/getWallet");
        let walletInfo = this.$store.getters.walletInfo;
        let walletBalance = (
          (walletInfo.amount || 0) + (walletInfo.income || 0)
        ).toFixed(2);
        if (this.goldenNum > walletBalance) {
          _this.$emit("close");
          Toast("金币不足请充值");
          this.$router.push("/rechargePage?t=gold");
          return;
        }
        this.$store.commit("app/SET_LOADING", true);
        let ret = await this.$Api(goldExchange, req);
        if (ret && ret.code === 200) {
          this.buySuccess();
          this.$store.dispatch("user/getUserInfo");
          Toast("购买成功");
          _this.$emit("open");
          // _this.$emit("buySuccess");
          this.isRecharge = false;
        } else if (ret.code == 8000) {
          _this.$emit("close");
          this.$router.push('/rechargePage?t=gold')
        } else if (ret.tip) {
          Toast(ret.tip || "购买失败，请稍后再试");
        }
        this.$store.commit("app/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
      }
    },
    async volumeUnlock() {
      let req = {
        productType: this.videoType,
        productID: this.id,
        watchReadCouponID: this.couponList[0]?.id,
        isH5: true,
      };
    //   console.log(req, "--------------");
      // this.$emit("buySuccess");
      this.$emit("openGolden");
      try {
        this.$store.commit("app/SET_LOADING", true);
        let ret = await this.$Api(goldExchange, req);
        if (ret && ret.code === 200) {
          this.$store.dispatch("user/getUserInfo");
          Toast("解锁成功");
          this.$emit("open");
          this.$emit("buySuccess");
          this.isRecharge = false;
        } else if (ret.tip) {
          Toast(ret.tip || "解锁失败，请稍后再试");
        }
        this.$store.commit("app/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vipPopup {
  /deep/ .van-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2222;
  }

  .vipContent1 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .bgImg {
      width: 335px;
      height: 244px;
      //width: 100%;
      //height: 100%;
      // background-image: url("../../assets/png/vipPop.png");
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      //background-image: linear-gradient(to bottom, rgba(229, 254, 255, 1),rgba(255, 255, 255, 1));
     background: linear-gradient(180deg, #FFFCE0 0%, #FFFFFF 30%);
     border-radius: 18px;

      .openBtn {
        // padding-top: 288.2px;
        // display: flex;
        // justify-content: center;

        img {
          width: 148px;
          height: 47px;
        }
        .vipTitle {
          font-size: 24px;
          color: #333333;
          text-align: center;
          padding-top: 18px;
          font-weight: 500;
          //border-bottom: solid 1px rgb(230,230,230);
        }
      }

      .vipNum {
        margin-top: 18px;
        text-align: center;
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
      }

      .Continue {
        margin-top: 20px;
        font-size: 14px;
        color: #333333;
        font-weight: 400;
        text-align: center;
      }

      .vipBtn {
        margin-top: 25px;
        display: flex;
        justify-content: center;
        text-align: center;
        color: rgba(66, 66, 66, 1);
        font-size: 16px;
        font-weight: 500;
        line-height: 46px;
        .openBtn {
            width: 129px;
            height: 46px;
            background: rgba(251, 209, 61, 1);
            border-radius: 25px;
          }

          .textBtn {
            font-weight: 400;
            width: 108px;
            height: 46px;
            background: rgba(240, 240, 249, 1);
            border-radius: 25px;
            margin-right: 12px;
            color: rgba(102, 102, 102, 1);
           font-size: 16px;
          }
        }
        .ticket {
          text-align: center;
          font-weight: 400;
          color: #333333;
          font-size: 12px;
          font-weight: 400;
          margin-top: 18px;
        }
    }

    .closeBtn {
      display: flex;
      justify-content: center;
      margin-top: 44px;

      img {
        width: 31px;
        height: 31px;
      }
    }
  }

  .vipContent3 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .bgImg {
      width: 335px;
      height: 299px;
      //width: 100%;
      //height: 100%;
      // background-image: url("../../assets/png/goldenPop.png");
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
     background: linear-gradient(180deg, #FFFCE0 0%, #FFFFFF 30%);
     border-radius: 18px;
      .unlockGolden {
        // padding-top: 238.2px;
        // display: flex;
        // justify-content: center;
        .goldTitle {
          font-size: 24px;
          color: rgba(51, 51, 51, 1);
          text-align: center;
          padding-top: 30px;
          font-weight: 600;
          //padding-bottom: 18px;
          //border-bottom: solid 1px rgb(230,230,230);
        }

        img {
          width: 203px;
          height: 35px;
        }
      }

      .goldenNum {
        margin-top: 20px;
        // width: 38px;
        text-align: center;
        // position: absolute;
        // margin-left: 145px;
        // margin-top: -26.2px;
        font-weight: 500;
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
        .golds {
          font-weight: bolder;
          color: rgb(252, 65, 98);
          font-size: 36px;
        }
      }

      .Continue {
        margin-top: 20px;
        font-size: 14px;
        color: #333333;
        font-weight: 400;
        text-align: center;
      }

      .goldBtn {
        margin-top: 25px;
        display: flex;
        justify-content: center;
        text-align: center;
        color: rgba(66, 66, 66, 1);
        font-size: 16px;
        font-weight: bolder;
        line-height: 46px;
        .openBtn {
            width: 129px;
            height: 46px;
            background: rgba(251, 209, 61, 1);
            border-radius: 25px;
            // padding-top: 15px;
            // display: flex;
            // justify-content: center;

            img {
              width: 148px;
              height: 47px;
            }
          }

          .textBtn {
            font-weight: 400;
            width: 129px;
            height: 46px;
           color: rgba(102, 102, 102, 1);
            background: rgba(240, 240, 249, 1);
            border-radius: 25px;
            margin-right: 12px;
            // display: flex;
            // justify-content: center;
            // padding-top: 9px;
          }
        }
        .ticket {
          text-align: center;
          font-weight: 400;
          color: #333333;
          font-size: 12px;
          font-weight: 400;
          margin-top: 18px;
        }
      }

    .closeBtn {
      display: flex;
      justify-content: center;
      margin-top: 44px;

      img {
        width: 31px;
        height: 31px;
      }
    }
  }

  .vipContent4 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .bgImg {
      width: 280px;
      height: 387.2px;
      //width: 100%;
      //height: 100%;
      background-image: url("../../assets/png/goldenPop.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .unlockGolden {
        padding-top: 238.2px;
        display: flex;
        justify-content: center;

        img {
          width: 203px;
          height: 35px;
        }
      }

      .goldenNum {
        width: 38px;
        text-align: center;
        position: absolute;
        margin-left: 145px;
        margin-top: -26.2px;
        color: rgb(1, 255, 254);
      }

      .openBtn {
        padding-top: 15px;
        display: flex;
        justify-content: center;

        img {
          width: 148px;
          height: 47px;
        }
      }

      .textBtn {
        display: flex;
        justify-content: center;
        padding-top: 9px;
      }
    }

    .closeBtn {
      display: flex;
      justify-content: center;
      margin-top: 44px;

      img {
        width: 31px;
        height: 31px;
      }
    }
  }
}
</style>
