import {getLocalItem, getSessionItem, setLocalItem} from "@/utils/longStorage";
import {getAnnounce} from "@/api/movies";
import {Api} from "@/utils";

const state = {
    baseUrl: getSessionItem("baseUrl") || "", //选线过后的api域名
    appConfig: sessionStorage.getItem("appConfig") || {},  //全局配置信息
    UID: getLocalItem("UID") || "",    //用户唯一ID
    keepAliveList: [], //需要缓存的页面
    globalLoading: false, // 全局loading
    marqueeList: sessionStorage.getItem("marqueeList") || [], // 跑马灯
    homeRefreshDisable: false, //首页下拉禁用
    bankCard: {
        id: "",
        actName: "",
        act: "",
        bankCode: "",
        cardType: "",
    }, // 选中的银行卡信息
}

const mutations = {
    SET_BASEURL(state, baseUrl) {
        state.baseUrl = baseUrl;
    },
    SET_CONFIG_DATA(state, data) {
        state.appConfig = data;
    },
    SET_UID(state, uid) {
        state.UID = uid
    },
    SET_KEEP: (state, value) => {
        state.keepAliveList = value
    },
    SET_LOADING: (state, value) => {
        state.globalLoading = value
    },
    SET_HOMEREFRESHDISABLE: (state, sta) => {
        state.homeRefreshDisable = sta
    },
    SET_MARQUEE_LIST: (state, list) => {
        state.marqueeList = list
    },
    SET_BANKCARD: (state, data) => {
        state.bankCard = data
    }
}

const actions = {
    GET_UID({commit}) {
        let len = 16;
        let radix = 16;
        var chars =
            "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
                ""
            );
        var uuid = [],
            i;
        radix = radix || chars.length;

        if (len) {
            // Compact form
            for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
        } else {
            // rfc4122, version 4 form
            var r;

            // rfc4122 requires these characters
            uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
            uuid[14] = "4";

            // Fill in random data. At i==19 set the high bits of clock sequence as
            // per rfc4122, sec. 4.1.5
            for (i = 0; i < 36; i++) {
                if (!uuid[i]) {
                    r = 0 | (Math.random() * 16);
                    uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
                }
            }
        }
        let uid = uuid.join("") + Date.now();
        setLocalItem("UID", uid)
        commit("SET_UID", uid)
    },
    async getMarquee({commit}) {
        let res = await Api(getAnnounce, undefined);
        if (res.code === 200) {
            sessionStorage.setItem("marqueeList",JSON.stringify(res.data.announcement))
            commit('SET_MARQUEE_LIST', res.data.announcement)
        }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
