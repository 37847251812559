import axios from "@/utils/request";

// 登录
export function userLogin(data) {
  return axios.post(`mine/login/h5`, data);
}
// 账号凭证登录
export function loginGuest(data) {
  return axios.post(`mine/login/h5/qr`, data);
}
// 手机号登录
export function phoneLogin(data) {
  return axios.post(`mine/mobileLoginOnly`, data);
}
// 绑定推广码
export function bindInviteCode(data) {
  return axios.post(`mine/inviteBind`, data);
}

// 查询头像、背景列表
export function queryAvatarList(data) {
  return axios.get(`mine/userresource/list`, {
    params: data,
  });
}
// 查询用户信息
export function queryUserInfo(data) {
  return axios.get(`mine/info`, {
    params: data,
  });
}
// 补签打卡
export function reSigin(data) {
  return axios.post(`sign_record/resign`, data);
}
// 修改用户信息
export function modifyUserInfo(data) {
  return axios.post(`mine/info`, data);
}

// 查询钱包信息
export function queryWallet() {
  return axios.get(`mine/wallet`);
}

// 查询用户权益
export function queryRights() {
  return axios.get(`mine/rights`);
}

// 发送验证码 1-绑定手机号 2-手机号登陆
export function sendCode(data) {
  return axios.post(`notification/captcha`, data);
}

// 绑定-换绑手机号
export function mobileBind(data) {
  return axios.post(`mine/mobileBind`, data);
}

//点赞接口
export function zan(data) {
  return axios.post(`thumbsUp`, data);
}

//取消点赞接口
export function cancleZan(data) {
  return axios.post(`thumbsDown`, data);
}

//关注或者取消关注接口博主
export function careOrcancle(data) {
  return axios.post(`mine/follow`, data);
}

//收藏/取消视频
/**"video"   //视频
"movie" //影视
"img"   //图文
"tag"  //话题
 */
export function careCollectVideo(data) {
  return axios.post(`mine/collect`, data);
}

//评论回复
export function commentSend(data) {
  return axios.post(`comment/send`, data);
}

// 查询评论列表
export function queryCommentList(data) {
  return axios.get(`comment/list`, {
    params: data,
  });
}

// 查询2级评论
export function queryCommentListlever2(data) {
  return axios.get(`comment/info`, {
    params: data,
  });
}

//转发
export function forwardVid(data) {
  return axios.post(`vid/forward`, data);
}

//签到
export function taskSIgnPost(data) {
  return axios.post(`task/sign`, data);
}

//积分兑换
export function taskExchange(data) {
  return axios.post(`/point/exchange`, data);
}

//获取签到详情
export function taskSIgnGet() {
  return axios.get(`task/sign`);
}

// 查询任务列表
export function querySignList() {
  return axios.get(`/task/newtask`);
}

// 任务兑换列表
export function queryExchangeTaskList(data) {
  return axios.get(`/point/list`, {
    params: data,
  });
}

/**
 * 做任务
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function taskDo1(data) {
  return axios.post(`/task/completeDaily`, data);
}

/**
 * 做任务
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function taskDo2(data) {
  return axios.post(`/task/completeOnce`, data);
}

/**
 * 积分兑换实物收获地址
 */
export function taskExchangeEntity(data) {
  return axios.post(`/point/inkind`, data);
}

/**
 * 去做任务
 */
export function toTaskClick(data) {
  return axios.post(`/task/onceClick`, data);
}

/*********************** 账号凭证 ******************/
// 查询账号凭证
export function queryCertificate(data) {
  return axios.get(`mine/certificate/qr`, { params: data });
}

//获取客服链接接口
export function getService(data) {
  return axios.get(`im/whiteSign`, {
    params: data,
  });
}
