<template>
    <div v-html="highlightedText" @click.stop="handleKeywordClick"></div>
</template>

<script>
import {innerJump2} from '@/utils/getConfig'
export default {
    props: {
        text: {
            type: String,
            required: true,
            default() {
                return '';
            },
        },
        keyword: {
            type: String,
            required: false,
            default() {
                return '';
            },
        },
        linkType: {
            type: Number,
            required: false,
            default() {
                return 1;
            },
        },
        linkStr: {
            type: String,
            required: false,
            default() {
                return '';
            },
        },
    },
    computed:{
        highlightedText(){
            const keyword = this.keyword;
            if (!keyword) return this.text;
            let result = '';
            let currentIndex = 0;
            const index = this.text.indexOf(keyword, currentIndex);
            if (index !== -1) {
                result += this.text.slice(currentIndex, index);
                result += `<span style="color:#94D6DA">${keyword}</span>`;
                currentIndex = index + keyword.length;
            }
            result += this.text.slice(currentIndex);
            return result;
        }
    },
    methods:{
        handleKeywordClick(e){
            const keyword = this.keyword;
            const linkType = this.linkType;
            const linkStr = this.linkStr;
            if (!keyword || !linkStr) return;

            const target = e.target;
            if (target.tagName === 'SPAN') {
                const index = linkStr.indexOf('yinseinner://');
                if (linkType === 2 || index !== -1) {
                    innerJump2(linkStr.substring(13));
                } else {
                    window.open(linkStr);
                }
            }
        }
    }
}
</script>

<style scoped>
    .highlighted {
        color: #94D6DA;
        cursor: pointer;
        text-decoration: underline;
    }
</style>