import {getMoudles} from "@/api/movies";
import {Api} from "@/utils";

const state = {
    showBackToTop: false
}

const mutations = {
    setShowBackToTop(state, value) {
        state.showBackToTop = value
    }
}

const actions = {
    setShowBackToTop: ({ commit }, value) => {
        commit('movies/setShowBackToTop', value, { root: true });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
