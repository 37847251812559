<template>
    <div class="RewardPop">
        <van-overlay :show="rewarDate.show">
            <div>
                <div class="reward_box">
                    <div class="rewardTitle">打赏帖子金币</div>
                    <div class="moneyList">
                        <div class="moneyItem" :class="{activeMoney: (activeIndex === index) && !amountVal}" v-for="(item, index) in rewardMoney" :key="index" @click="selectAmount(index)">
                            <svg-icon class="goldenBeans" icon-class="goldenBeans1"></svg-icon>
                            <div class="goldenBeanNum">{{item}}颗</div>
                        </div>
                    </div>
                    <div class="amontBox">
                        自定义
                        <van-field class="inputItem" type="number" input-align="center" center border :maxlength="8" v-model="amountVal"></van-field>
                        颗金币
                    </div>
                    <div class="rewardBtn" @click="reward">确认打赏</div>
                    <div class="closeBtn" @click="closePop">稍后再说</div>
                </div>
                <div class="closeBtn" @click="closePop">
                    <img src="@/assets/png/vipPopBtn.png">
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import { Overlay, Field, Toast } from "vant";
    import { reward } from "@/api/mine";
    import {mapGetters} from "vuex";
    export default {
        components: {
            [Field.name]: Field,
            [Overlay.name]: Overlay,
        },
        computed: {
          ...mapGetters({
            rewarDate: 'rewarDate',
          })
        },
        data() {
            return {
                rewardMoney: [],
                amountVal: "",
                activeIndex: -1,
            }
        },
        created() {
            if (Object.prototype.toString.call(this.$store.state.app.appConfig) === "[object String]") {
              this.rewardMoney = JSON.parse(this.$store.state.app.appConfig)?.rewardMoney ?? [];
            } else {
              this.rewardMoney = this.$store.state.app.appConfig?.rewardMoney ?? [];
            }
            // alert(JSON.stringify(this.rewardMoney))
        },
        methods: {

            // 选择金豆
            selectAmount(index) {
                this.activeIndex = index;
                this.amountVal = "";
            },
            // 关闭弹窗
            closePop() {
                this.activeIndex = -1;
                this.amountVal = "";
                this.$store.commit("user/SET_REWAE", {show: false, info: {}})
            },
            // 打赏
            async reward() {
                let req = {
                    coins: String(this.amountVal?this.amountVal:this.activeIndex > -1 ? this.rewardMoney[this.activeIndex]:0),
                    msg: "",
                    vid: this.rewarDate.info.id
                }
                if (!req.coins || req.coins == 0) {
                    Toast('请选择或输入打赏数量');
                    return;
                }
                this.$store.commit('app/SET_LOADING', true);
                try {
                    let res = await this.$Api(reward, req);
                    this.$store.commit('app/SET_LOADING', false);
                    if (res.code === 200) {
                        Toast("打赏成功");
                        this.closePop();
                    } else {
                        Toast(res.tip || "打赏失败，请稍后再试");
                    }
                } catch (error) {
                    this.$store.commit('app/SET_LOADING', false);
                    Toast("打赏失败，请稍后再试");
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .van-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
    }

    .RewardPop {

        .closeBtn{
            display: flex;
            justify-content: center;
            margin-top: 36px;

            img{
                width: 31px;
                height: 31px;
            }
        }

        .reward_box {
            width: 280px;
            // height: 375px;
            padding: 18px 18px;
            border-radius: 9px;
            box-sizing: border-box;
            // background: #1b164c;
           background: linear-gradient(180deg, #FFFCE0 0%, #FFF 30%);
            //background: url("../../assets/png/gradientBg.png") no-repeat #fff;
            background-size: contain;
            .rewardTitle {
                font-size: 18px;
                text-align: center;
                color: rgb(0,0,0);
                font-weight: bold;
            }
            .moneyList {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-row-gap: 12px;
                grid-column-gap: 5px;
                margin: 18px 0 21px;
                .moneyItem {
                    width: 55px;
                    height: 70px;
                    background: #322f5e00;
                    border: 1px solid #FBD13D;
                    border-radius: 6px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .goldenBeans {
                        width: 43px;
                        height: 35px;
                    }
                    .goldenBeanNum {
                        font-size: 12px;
                        color: #999999;
                    }
                }
                .activeMoney {
                    border: 1px solid #FBD13D;
                    background: #FBD13D;
                    .goldenBeanNum {
                        color: #333;
                    }
                }
            }
            .amontBox {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000;
                font-size: 15px;
                .inputItem {
                    padding: 0;
                    margin: 0 12px;
                    background: #e6e6e6;
                    width: 45px;
                    height: 25px;
                    // border: 1px solid #fff;
                    border-radius: 6px;
                    /deep/ .van-field__control {
                      font-size: 12px;
                      color: #000;
                    }
                }
            }
            .rewardBtn {
                margin: 24px auto 12px;
                width: 218px;
                height: 39px;
                border-radius: 39px;
                line-height: 39px;
                font-size: 18px;
                text-align: center;
                color: #424242;
                background: #FBD13D;
            }
            .closeBtn {
                width: 60px;
                height: 22px;
                font-size: 14px;
                color: #999999;
                margin: 0 auto
            }
        }
    }
</style>
