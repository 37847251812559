import Vue from "vue";
import VueRouter from "vue-router";

const includPush = VueRouter.prototype.push;
const includReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
  return includPush.call(this, location).catch((err) => err);
};
VueRouter.prototype.replace = function replace(location) {
  return includReplace.call(this, location).catch((err) => err);
};
import Main from "@/views/Main";
import Splash from "@/views/splash/index"; //启动页

/*********************** 首页 ******************/
const Home = () => import("@/views/home");
// const Featured = () => import("@/views/home/featured")

/*********************** 圈子 ******************/
const Community = () => import("@/views/community");

/*********************** 福利 ******************/
const Welfare = () => import("@/views/welfare");

/*********************** 书架 ******************/
const Bookshelf = () => import("@/views/bookshelf");

/*********************** 我的  ******************/
const Mine = () => import("@/views/mine"); //我的

/*********************** 原创  ******************/
const Original = () => import("@/views/original/index.vue"); //原创

/*********************** 更多视频  ******************/
const TopicPage = () => import("@/views/topicPage");

/*********************** 热门排行榜  ******************/
const HotRankingPage = () => import("@/views/hotRankingPage");

/*********************** 福利列表（子页面，带返回）  ******************/
const WelfareCenter = () => import("@/views/welfare/WelfareCenter");

/*********************** 福利详情  ******************/
const WelfareDetails = () => import("@/views/welfare/WelfareDetails");

/*********************** 热门分类  ******************/
const CategoriesPage = () => import("@/views/categoriesPage");
/*********************** 专题列表  ******************/
const TagList = () => import("@/views/tagList");

/*********************** 视频详情页  ******************/
const VideoDetailsPage = () => import("@/views/videoDetailsPage");

/*********************** 小说/漫画详情页  ******************/
const NovelDetailsPage = () => import("@/views/novelDetailsPage");

/*********************** 漫画阅读页  ******************/
const ComicReader = () => import("@/views/novelDetailsPage/comicReader");

/*********************** 小说阅读页  ******************/
const Reader = () => import("@/views/novelDetailsPage/reader");

/*********************** 图集详情页  ******************/
const PortrayPage = () => import("@/views/portrayPage");

/*********************** 图片预览  ******************/
const PicturePreview = () => import("@/views/community/picturePreview");

/*********************** 帖子详情  ******************/
const PostDetails = () => import("@/views/community/postDetails");

/*********************** 热门话题  ******************/
const HotTopic = () => import("@/views/hotTopic");

/*********************** 官方博主  ******************/
const AuthorityBlogger = () => import("@/views/authorityBlogger");

/*********************** 话题详情  ******************/
const TopicDetails = () => import("@/views/topicDetails");

/*********************** 标签作品列表  ******************/
const TagsDetailPage = () => import("@/views/tagsDetailPage");

/*********************** 发布作品  ******************/
const ReleasePage = () => import("@/views/releasePage");

/*********************** 短视频播放  ******************/
const ShortVideo = () => import("@/views/shortVideo");

/*********************** 他的关注  ******************/
const UserConcern = () => import("@/views/userConcern");

/*********************** 他的粉丝  ******************/
const UserFans = () => import("@/views/userFans");

/*********************** 专题列表  ******************/
const SpecialTopic = () => import("@/views/specialTopic");

/*********************** 搜索页  ******************/
const SearchPage = () => import("@/views/searchPage");

/*********************** 搜索页结果页  ******************/
const SearchResultPage = () => import("@/views/searchPage/searchResultPage");

/*********************** 楼凤页  ******************/
const Loufeng = () => import("@/views/loufeng");

const Setting = () => import("@/views/mine/setting"); // 设置
const EditInfo = () => import("@/views/mine/editInfo"); // 编辑资料
const EditAvatar = () => import("@/views/mine/editInfo/editAvatar"); // 编辑资料-编辑头像
const EditBackground = () => import("@/views/mine/editInfo/editBackground"); // 编辑资料-更换背景
const EditName = () => import("@/views/mine/editInfo/editName"); // 编辑资料-修改昵称
const EditIntroduction = () => import("@/views/mine/editInfo/editIntroduction"); // 编辑资料-修改简介
const InviteCode = () => import("@/views/mine/inviteCode"); // 绑定邀请码
const BindPhone = () => import("@/views/mine/bindPhone"); // 绑定手机
const RetrieveAct = () => import("@/views/mine/retrieveAct"); // 账号找回
const PhoneRetrieval = () => import("@/views/mine/retrieveAct/phoneRetrieval"); // 账号找回-手机号找回
const CountryCode = () => import("@/views/mine/bindPhone/countryCode"); // 国家区号选择
const CommonProblem = () => import("@/views/mine/commonProblem"); // 常见问题
const PaymentIssues = () => import("@/views/mine/commonProblem/paymentIssues"); // 常见问题-支付问题
const ActIssues = () => import("@/views/mine/commonProblem/actIssues"); // 常见问题-账号问题
const VirusTips = () => import("@/views/mine/commonProblem/virusTips"); // 常见问题-报毒提示
const AboutUs = () => import("@/views/mine/commonProblem/aboutUs"); // 常见问题-关于我们
const AccountCredentials = () => import("@/views/mine/accountCredentials"); // 账号凭证
const FocusList = () => import("@/views/mine/focusList"); // 关注列表
const FanList = () => import("@/views/mine/fanList"); // 粉丝列表
const UserHomepage = () => import("@/views/mine/userHomepage"); // 用户主页
const ExchangeCode = () => import("@/views/mine/exchangeCode"); // 领取兑换
const Group = () => import("@/views/mine/group"); // 官方社群
const BusinessCooperation = () => import("@/views/mine/businessCooperation"); // 商务合作
const OfficialGround = () => import("@/views/mine/officialGround"); // 商务合作
const AppCenter = () => import("@/views/mine/appCenter"); // 应用中心
const Sign = () => import("@/views/mine/sign"); // 福利签到
const SignIn = () => import("@/views/mine/signIn"); // 打卡签到
const SignRule = () => import("@/views/mine/sign/rule"); // 签到规则
const RechargePage = () => import("@/views/mine/recharge"); // 充值 会员/金豆
const CustomerService = () => import("@/views/customerService"); // 客服
const Payfail = () =>
  import("@/views/mine/recharge/VipItem/questionDetails/payfail"); // 问题详情-支付失败
const DuplicatePay = () =>
  import("@/views/mine/recharge/VipItem/questionDetails/duplicatePay"); // 问题详情-重复支付
const NotVip = () =>
  import("@/views/mine/recharge/VipItem/questionDetails/notVip"); // 问题详情-没有会员
const Bill = () => import("@/views/mine/bill"); // 账单明细
const ExpensesRecord = () => import("@/views/mine/expensesRecord"); // 消费记录
const SharePromotion = () => import("@/views/mine/sharePromotion"); // 分享推广
const PromotionRecord = () =>
  import("@/views/mine/sharePromotion/promotionRecord"); // 推广记录
const CreationCenter = () => import("@/views/mine/creationCenter"); // 创造中心
const PublishImg = () => import("@/views/publish/publishImg"); // 发布-图文
const PublishVideo = () => import("@/views/publish/publishVideo"); // 发布-视频
const UploadRules = () => import("@/views/publish/uploadRules"); // 规则说明
const Rules = () => import("@/views/publish/rules"); // 规则说明
const SelectTag = () => import("@/views/publish/selectTag"); // 选择话题
const ReviewResult = () => import("@/views/mine/creationCenter/reviewResult"); // 上传失败原因
const RevenueCenter = () => import("@/views/mine/revenueCenter"); // 收益中心
const Withdraw = () => import("@/views/mine/withdraw"); // 收益提现
const BankCardList = () => import("@/views/mine/withdraw/bankCardList"); // 银行卡列表
const WithdrawalDetails = () =>
  import("@/views/mine/withdraw/withdrawalDetails"); // 收益提现
const MyMsg = () => import("@/views/mine/myMsg"); // 我的消息
const Purchased = () => import("@/views/mine/purchased"); // 已购
const HistoryRecord = () => import("@/views/mine/historyRecord"); // 历史记录
const Collect = () => import("@/views/mine/collect"); // 我的收藏
const CommentDetails = () => import("@/views/commentDetails"); // 评论详情
const AiUndress = () => import("@/views/mine/aiUndress"); //AI脱衣
const AiRecord = () => import("@/views/mine/aiRecord"); //AI脱衣记录
const InProgress = () => import("@/views/mine/aiRecord/inProgress"); //AI脱衣记录-进行中
const Generated = () => import("@/views/mine/aiRecord/generated"); //AI脱衣记录-生成成功
const GenerateFail = () => import("@/views/mine/aiRecord/generateFail"); //AI脱衣记录-生成失败
// const LikeList = () => import("@/views/mine/likeList")   // 点赞列表

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Splash",
    component: Splash,
  },
  {
    path: "/setting",
    name: "Setting",
    component: Setting,
  },
  {
    path: "/editInfo",
    name: "EditInfo",
    component: EditInfo,
  },
  {
    path: "/editAvatar",
    name: "EditAvatar",
    component: EditAvatar,
  },
  {
    path: "/editBackground",
    name: "EditBackground",
    component: EditBackground,
  },
  {
    path: "/editName",
    name: "EditName",
    component: EditName,
  },
  {
    path: "/editIntroduction",
    name: "EditIntroduction",
    component: EditIntroduction,
  },
  {
    path: "/inviteCode",
    name: "InviteCode",
    component: InviteCode,
  },
  {
    path: "/bindPhone",
    name: "BindPhone",
    component: BindPhone,
  },
  {
    path: "/retrieveAct",
    name: "RetrieveAct",
    component: RetrieveAct,
  },
  {
    path: "/phoneRetrieval",
    name: "PhoneRetrieval",
    component: PhoneRetrieval,
  },
  {
    path: "/countryCode",
    name: "CountryCode",
    component: CountryCode,
  },
  {
    path: "/commonProblem",
    name: "CommonProblem",
    component: CommonProblem,
  },
  {
    path: "/paymentIssues",
    name: "PaymentIssues",
    component: PaymentIssues,
  },
  {
    path: "/actIssues",
    name: "ActIssues",
    component: ActIssues,
  },
  {
    path: "/virusTips",
    name: "VirusTips",
    component: VirusTips,
  },
  {
    path: "/aboutUs",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/accountCredentials",
    name: "AccountCredentials",
    component: AccountCredentials,
  },
  {
    path: "/focusList",
    name: "FocusList",
    component: FocusList,
  },
  {
    path: "/fanList",
    name: "FanList",
    component: FanList,
  },
  {
    path: "/userHomepage",
    name: "UserHomepage",
    component: UserHomepage,
  },
  {
    path: "/exchangeCode",
    name: "ExchangeCode",
    component: ExchangeCode,
  },
  {
    path: "/group",
    name: "Group",
    component: Group,
  },
  {
    path: "/businessCooperation",
    name: "BusinessCooperation",
    component: BusinessCooperation,
  },
  {
    path: "/officialGround",
    name: "OfficialGround",
    component: OfficialGround,
  },
  {
    path: "/appCenter",
    name: "AppCenter",
    component: AppCenter,
  },
  {
    path: "/signIn",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/sign",
    name: "Sign",
    component: Sign,
  },
  {
    path: "/signRule",
    name: "SignRule",
    component: SignRule,
  },
  {
    path: "/customerService",
    name: "CustomerService",
    component: CustomerService,
  },
  {
    path: "/payfail",
    name: "Payfail",
    component: Payfail,
  },
  {
    path: "/duplicatePay",
    name: "DuplicatePay",
    component: DuplicatePay,
  },
  {
    path: "/notVip",
    name: "NotVip",
    component: NotVip,
  },
  {
    path: "/bill",
    name: "Bill",
    component: Bill,
  },
  {
    path: "/expensesRecord",
    name: "ExpensesRecord",
    component: ExpensesRecord,
  },
  {
    path: "/sharePromotion",
    name: "SharePromotion",
    component: SharePromotion,
  },
  {
    path: "/promotionRecord",
    name: "PromotionRecord",
    component: PromotionRecord,
  },
  {
    path: "/creationCenter",
    name: "CreationCenter",
    component: CreationCenter,
  },
  {
    path: "/publishImg",
    name: "PublishImg",
    component: PublishImg,
  },
  {
    path: "/publishVideo",
    name: "PublishVideo",
    component: PublishVideo,
  },
  {
    path: "/uploadRules",
    name: "UploadRules",
    component: UploadRules,
  },
  {
    path: "/rules",
    name: "Rules",
    component: Rules,
  },
  {
    path: "/selectTag",
    name: "SelectTag",
    component: SelectTag,
  },
  {
    path: "/reviewResult",
    name: "ReviewResult",
    component: ReviewResult,
  },
  {
    path: "/revenueCenter",
    name: "RevenueCenter",
    component: RevenueCenter,
  },
  {
    path: "/withdraw",
    name: "Withdraw",
    component: Withdraw,
  },
  {
    path: "/withdrawalDetails",
    name: "WithdrawalDetails",
    component: WithdrawalDetails,
  },
  {
    path: "/bankCardList",
    name: "BankCardList",
    component: BankCardList,
  },
  {
    path: "/myMsg",
    name: "MyMsg",
    component: MyMsg,
  },
  {
    path: "/purchased",
    name: "Purchased",
    component: Purchased,
  },
  {
    path: "/historyRecord",
    name: "HistoryRecord",
    component: HistoryRecord,
  },
  {
    path: "/collect",
    name: "Collect",
    component: Collect,
  },
  {
    path: "/commentDetails",
    name: "CommentDetails",
    component: CommentDetails,
  },
  {
    path: "/rechargePage",
    name: "RechargePage",
    component: RechargePage,
  },
  {
    path: "/main",
    name: "Main",
    component: Main,
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
      },
      {
        path: "/community",
        name: "Community",
        component: Community,
      },
      {
        path: "/welfare",
        name: "Welfare",
        component: Welfare,
      },
      {
        path: "/loufeng",
        name: "Loufeng",
        component: Loufeng,
      },
      {
        path: "/bookshelf",
        name: "Bookshelf",
        component: Bookshelf,
      },
      {
        path: "/mine",
        name: "Mine",
        component: Mine,
      },
      {
        path: "/original",
        name: "original",
        component: Original,
      },
    ],
  },
  {
    path: "/topicPage",
    name: "TopicPage",
    component: TopicPage,
  },
  {
    path: "/hotRankingPage",
    name: "HotRankingPage",
    component: HotRankingPage,
  },
  {
    path: "/welfareCenter",
    name: "WelfareCenter",
    component: WelfareCenter,
  },
  {
    path: "/welfareDetails",
    name: "WelfareDetails",
    component: WelfareDetails,
  },
  {
    path: "/categoriesPage",
    name: "CategoriesPage",
    component: CategoriesPage,
  },
  {
    path: "/tagList",
    name: "TagList",
    component: TagList,
  },
  {
    path: "/videoDetailsPage",
    name: "VideoDetailsPage",
    component: VideoDetailsPage,
  },
  {
    path: "/novelDetailsPage",
    name: "NovelDetailsPage",
    component: NovelDetailsPage,
  },
  {
    path: "/comicReader",
    name: "ComicReader",
    component: ComicReader,
  },
  {
    path: "/portrayPage",
    name: "PortrayPage",
    component: PortrayPage,
  },
  {
    path: "/reader",
    name: "Reader",
    component: Reader,
  },
  {
    path: "/picturePreview",
    name: "PicturePreview",
    component: PicturePreview,
  },
  {
    path: "/postDetails",
    name: "PostDetails",
    component: PostDetails,
  },
  {
    path: "/hotTopic",
    name: "HotTopic",
    component: HotTopic,
  },
  {
    path: "/authorityBlogger",
    name: "AuthorityBlogger",
    component: AuthorityBlogger,
  },
  {
    path: "/releasePage",
    name: "ReleasePage",
    component: ReleasePage,
  },
  {
    path: "/shortVideo",
    name: "ShortVideo",
    component: ShortVideo,
  },
  {
    path: "/userConcern",
    name: "UserConcern",
    component: UserConcern,
  },
  {
    path: "/userFans",
    name: "UserFans",
    component: UserFans,
  },
  {
    path: "/specialTopic",
    name: "SpecialTopic",
    component: SpecialTopic,
  },
  {
    path: "/searchPage",
    name: "SearchPage",
    component: SearchPage,
  },
  {
    path: "/searchResultPage",
    name: "SearchResultPage",
    component: SearchResultPage,
  },
  {
    path: "/topicDetails",
    name: "TopicDetails",
    component: TopicDetails,
  },
  {
    path: "/tagsDetailPage",
    name: "TagsDetailPage",
    component: TagsDetailPage,
  },
  {
    path: "/aiUndress",
    name: "AiUndress",
    component: AiUndress,
  },
  {
    path: "/changeDetail", //ai视频换脸详情
    name: "changeDetail",
    component: () => import("@/views/mine/aiUndress/changeDetail.vue"),
  },
  {
    path: "/aiRecord",
    name: "AiRecord",
    component: AiRecord,
    redirect: "/aiRecord",
    // redirect: "/aiRecord/inProgress",
    children: [
      {
        path: "/aiRecord",
        name: "InProgress/inProgress",
        component: InProgress,
      },
      {
        path: "/aiRecord/generated",
        name: "Generated",
        component: Generated,
      },
      {
        path: "/aiRecord/generateFail",
        name: "GenerateFail",
        component: GenerateFail,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
