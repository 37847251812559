<template>
  <van-tabbar  @change="change" v-model="active" active-color="#000000" inactive-color="#C6C6C6" class="tabbar-warp" :safe-area-inset-bottom="true">
    <van-tabbar-item @click="backToTop" v-if="showBackToTop" :icon="backTopIconActive" name="backTop">顶部
    </van-tabbar-item>
    <van-tabbar-item v-else :icon="active == 'Home' ? homeIconActive :homeIcon" name="Home" to="/home">首页
    </van-tabbar-item>
    <van-tabbar-item :icon="active == 'Community' ? communityIconActive: communityIcon" to="/community" name="Community">圈子
    </van-tabbar-item>
    <van-tabbar-item :icon="active == 'Original' ? yjIconActive: yjIcon" to="/original"  name="Original"></van-tabbar-item>
    <!-- <van-tabbar-item :icon="active == 'Welfare' ? welfareIconActive  : welfareIcon" name="Welfare" to="/welfare">福利
    </van-tabbar-item> -->
    <van-tabbar-item :icon="active == 'Loufeng' ? recreationIconActive  : recreationIcon" name="Loufeng" to="/loufeng">娱乐
    </van-tabbar-item>
    <!-- <van-tabbar-item to="/bookshelf" :icon="active == 'Bookshelf' ? bookshelfIconActive  : bookshelfIcon" name="Bookshelf">书架
    </van-tabbar-item> -->
    <van-tabbar-item :icon="active == 'Mine'? mineIconActive : mineIcon" to="/mine" name="Mine">我的</van-tabbar-item>
  </van-tabbar>
</template>
<script>
import {mapGetters} from "vuex";
// import {noRedMessage} from "@/api/app";
import {Tabbar, TabbarItem} from "vant";
import {firstUpperCase} from "@/utils";
import {getLoufengUrl} from "@/utils/getConfig";
// import {getSessionItem} from "@/utils/longStorage";

export default {
  name: "ShopCats",
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  computed: {
    ...mapGetters({
      unreadMessages: "unreadMessages",
    }),
   showBackToTop() {
    return this.$store.getters.getShowBackToTop
   },
  },
  data() {
    return {
      active: "Home",
      homeIcon: require("@/assets/png/homeIcon.png"),
      homeIconActive: require("@/assets/png/homeIconActive.png"),
      communityIcon: require("@/assets/png/communityIcon.png"),
      communityIconActive: require("@/assets/png/communityIconActive.png"),
      // welfareIcon: require("@/assets/png/welfareIcon.png"),
      // welfareIconActive: require("@/assets/png/welfareIconActive.png"),
      // bookshelfIcon: require("@/assets/png/bookshelfIcon.png"),
      // bookshelfIconActive: require("@/assets/png/bookshelfIconActive.png"),
      yjIcon: require("@/assets/png/yjHome.png"),
      yjIconActive: require("@/assets/png/yjHomeActive.png"),
      recreationIcon: require("@/assets/png/recreation.png"),
      recreationIconActive: require("@/assets/png/recreationActive.png"),
      mineIcon: require("@/assets/png/mineIcon.png"),
      mineIconActive: require("@/assets/png/mineIconActive.png"),
      backTopIconActive: require("@/assets/png/backTopIconActive.png"),
      loufengUrl: null,
    };
  },
  created() {
    this.refreshRoute(this.$route.path);
    this.loufengUrl = getLoufengUrl();
  },
  methods: {
    async queryMsgHasNew() {
     await this.$store.dispatch('user/updateMsgHasNew');
     this.msgHasNew = this.$store.getters.msgHasNew;
    },
    change() {
     if (this.active === 'Mine') {
       this.queryMsgHasNew();
      }
    },
    // 刷新路由
    refreshRoute(path) {
      if (path.match(/\/(\S*)\//)) {
        this.active = firstUpperCase(path.match(/\/(\S*)\//)[1]);
      } else {
        this.active = firstUpperCase(this.$route.name);
      }
    },
    backToTop() {
     const element = document.querySelector('.tab-content');
     if (element) {
      element.scrollTo({ top: 0, behavior: 'smooth' });
     }
    }
    // onMovies() {
    //   let path = getSessionItem('moviesPath') || '/movies';
    //   this.$router.replace(path);
    // },
    // onCommunity() {
    //   let path = getSessionItem('communityPath') || '/community';
    //   this.$router.replace(path);
    // },
    // refreshPage() {
    //   this.$store.commit('commnity/CHANGE_REFRESHSTA', true);
    // },
    // async noRedMessage() {
    //   let res = await this.$Api(noRedMessage);
    //   if (res && res.code == 200) {
    //     this.$store.commit('user/SET_UNREADMSG', res.data)
    //   }
    // }
  },
    watch:{
        $route(to, from){
            this.refreshRoute(this.$route.path);
           if (this.$route.path !== 'Home') {
            this.$store.commit('movies/setShowBackToTop', false);
          }
        },
    }
};
</script>
<style scoped lang="scss">
// .active-background {
//   background-color: #000 !important;
// }
// .active-background::before {
//   content: '' !important;
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   width: 100%;
//   height: 120px;
//   background-image: url('~@/assets/png/footerActive.png') !important;
//   background-repeat: no-repeat;
//   background-size: 100%;
// }
.tabbar-warp {
  width: 100%;
  height: 72px;
  // padding-top: 5px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  border: none !important;

  /deep/ .van-tabbar-item {
    height: 65px;
    margin-top: -4px;
    z-index: 9;
  }
  /deep/ .van-tabbar-item:nth-child(3) {
    // padding-top: 6px !important;
    margin-top: -6px !important;
    // margin: 5px !important;
    .van-tabbar-item__icon img {
      width: 42px !important;
      height: 31px !important;
    }
  }
}
//.tabbar-warp::before {
//  content: '';
//  position: absolute;
//  left: 0;
//  bottom: 0;
//  width: 100%;
//  height: 100px;
//  background-image: url('~@/assets/png/footerTab.png');
//  background-repeat: no-repeat;
//  background-size: 100%;
//}
/deep/ .van-info {
  top: -4px;
  border: none;
}
/deep/ .van-tabbar-item__icon {
  font-size: 30px;
  margin-bottom: 0px;
}
/deep/ .van-tabbar-item__icon img {
  background: transparent !important;
  width: 30px;
  height: 30px;
}
/deep/ .van-tabbar-item__text {
  font-size: 10px;
  line-height: 14px;
  // color: #C6C6C6;
}
/deep/ .van-tabbar-item {
  // padding: 0 0 34px;
  box-sizing: border-box;
}

.van-tabbar-item--active {
  background-color: transparent;
  /deep/ .van-tabbar-item__text {
    // color: #000 !important;
    font-weight: bolder !important;
  }
}


[class*="van-hairline"]::after {
  border: none;
}
</style>
