<template>
  <div class="main" @click.prevent>
    <keep-alive :include="include">
      <router-view />
    </keep-alive>
    <TabBar />
  </div>
</template>
<script>
import TabBar from "@/components/Tabbar";
export default {
  name: "Main",
  components: {
    TabBar,
  },
  data() {
    return {
      include: ["movies", "community"],
    };
  },
};
</script>
<style lang="scss" scoped>
.main {
  height: calc(100% - 78px);
  //overflow-y: auto;
}
</style>
