import axios from "@/utils/request";

import { getDataUrl,getUserInfo } from "@/utils/getConfig";

// 获取域名地址
export function queryApi(data) {
    return  axios.get(`ping/domain`, {
        params: data
    });
}

// 上传图片
export function uploadImg(data) {
    return axios({
        url: '/vid/uploadStatic/batch',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}
// 上传图片
export function upload(data) {
    return axios({
        url: '/vid/uploadStatic',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}

// 分片上传视频
export function upLoadVideo(data, processCallback) {
    return axios.post("vid/uploadDotJson", data, {
        headers: {
            'Content-Type': 'application/json'
        },
        onUploadProgress: processCallback
    })
}

//图片解密
export const imgDecyptApi = async url => {
    let encoder = new TextEncoder();
    let KEY = encoder.encode("2019ysapp7527");
    var imgUrl = url
    return new Promise(resolve => {
        let oReq = new XMLHttpRequest();
        oReq.open("GET", imgUrl, true);
        oReq.responseType = "arraybuffer";
        oReq.onload = function () {
            var arrayBuffer = oReq.response; // 注意:不是oReq.responseText
            if (arrayBuffer) {
                var bytes = new Uint8Array(arrayBuffer);
                for (let i = 0; i < 100; i++) {
                    bytes[i] ^= KEY[i % KEY.length]
                }
                let imgType = "image/png";
                if(url){
                    imgType = url.endsWith(".png")?"image/png":url.endsWith(".jpg")?"image/jpg":url.endsWith(".jpeg")?"image/jpg":url.endsWith(".gif")?"image/gif":"image/png";
                }
                const blob = new Blob([bytes], {
                    type: imgType
                })
                const imgUrl = URL.createObjectURL(blob)
                resolve(imgUrl)
            }
        };
        oReq.send("");
    }).catch(() => {
        console.log("解密失败");
    })
};


//广告点击
export function adClickReport(data) {
    return axios.post(`/ads/click`, data);
}


//上传接口
export function uploadStaticBatch(formData) {
    return axios.post(`vid/uploadStatic/batch`,formData,{
        'Content-type' : 'multipart/form-data'
    })
}


// 数据埋点
export function dataAdd(data) {

    let domainUrl = getDataUrl();

    if(!domainUrl){
        return;
    }

    let userInfo = getUserInfo();

    if(!userInfo){
        return;
    }

    let requestData = null;

    //视频数据
    if(data['dataType'] == 1){
        requestData = {
            "dataType": data['dataType'],
            "videoDatas":{
                "appId": 55,
                "videoId": data.videoId,
                "videoTitle":data.videoTitle,
                "userType":userInfo.vipLevel == 0?2:1
            }
        };
    }
    //标签数据
    if(data['dataType'] == 2){
        requestData = {
            "dataType": data['dataType'],
            "tagDatas":{
                "appId": 55,
                "tagsName":data.tagsName,
                "userType":userInfo.vipLevel == 0?2:1
            }
        };
    }

    //vip金币充值数据
    if(data['dataType'] == 3){
        requestData = {
            "dataType": data['dataType'],
            "vipGraphicss":{
                "appId":55,
                "graphicsId": data.graphicsId,
                "userType": userInfo.vipLevel  == 0?2:1,
                "graphicsType": data.graphicsType,
                "graphicsTitle": data.graphicsTitle
            }
        };
    }

    if(!requestData){
        return ;
    }

    return axios.post(domainUrl,requestData);
}
