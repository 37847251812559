const state = {
  maxEpisodes: {},  // 存储每本小说的 maxEpisode
}

const mutations = {
  // 设置 maxEpisode
  SET_MAX_EPISODE: (state, { novelId, maxEpisode }) => {
    state.maxEpisodes[novelId] = maxEpisode;
  },
}

const actions = {
  // 添加一个 action 提交 SET_MAX_EPISODE
  setMaxEpisode({ commit }, { novelId, maxEpisode }) {
    commit('SET_MAX_EPISODE', { novelId, maxEpisode });
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
