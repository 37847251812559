<template>
  <div class="commentPop">
    <van-popup
      v-model="commentPopDate.show"
      position="bottom"
      class="comment_pop"
      :lock-scroll="true"
      @close="commentClose"
    >
      <div class="headBox">
        <div class="leftItem">
          <div class="title">评论</div>
          <!--                    <div class="commentTotle">{{msgTotle}}</div>-->
        </div>
        <div class="closeBtn" @click="commentClose">
          <svg-icon class="newClose" iconClass="newClose" />
        </div>
      </div>

      <div class="contentBox" @click="clearReply">
        <PullRefresh
          :loading="loading"
          :refreshing="refreshing"
          :finished="finished"
          @onLoad="onLoad"
          @onRefresh="onRefresh"
          :isHigehtMax="true"
          :error="error"
          :isNoData="isNoData"
        >
          <div class="commentBox" v-for="item in list" :key="item.id">
            <div class="commentInfo">
              <div @click.stop="openPersonal(item)">
                <ImgDecypt
                  class="avatar"
                  round
                  :src="item.userPortrait"
                  :key="item.userPortrait"
                />
              </div>

              <div class="info">
                <div class="name">
                  <span>{{ item.userName }}</span>
<!--                  <svg-icon-->
<!--                    :iconClass="item.gender == 'male' ? 'maleMsg' : 'female'"-->
<!--                  ></svg-icon>-->
                </div>
                <div class="text msgContent" v-if="!item.isDelete">
                  <div style="width: calc(100% - 22px)">
                      <CommentJump
                          class="commit_content"
                          :text="item.content"
                          :keyword="item.searchKeyword"
                          :linkType="item.linkType"
                          :linkStr="item.linkStr"
                      />
                  </div>
                </div>
                <div class="text" style="color: rgb(153, 153, 153)" v-else>
                  内容已被删除
                </div>
                <div class="address" v-if="!item.isTop">
                  <div class="createdAt">
                    <span>{{ item.createdAt | time }}</span>
                  </div>

                  <div class="btnBox" v-if="!item.linkType">
                        <span @click.stop="reply(item, null)">
    <!--                      <svg-icon-->
    <!--                        class="community_info"-->
    <!--                        iconClass="community_info"-->
    <!--                      />-->
                          <div class="community_info"></div>
                        </span>
    
                        <span @click.stop="clickZan(item)">
                          <div class="postLikeedIcon" v-if="item.isLike"></div>
                          <div v-else class="postLikeIcon"></div>
    <!--                      <svg-icon-->
    <!--                        class="like icon"-->
    <!--                        v-if="item.isLike"-->
    <!--                        iconClass="postLikeedIcon"-->
    <!--                      />-->
    <!--                      <svg-icon-->
    <!--                        class="like icon"-->
    <!--                        v-else-->
    <!--                        iconClass="postLikeIcon"-->
    <!--                      />-->
                    </span>
                  </div>
                </div>
                <div v-else>
                  <span style="color: rgb(153, 153, 153); font-size: 12px"
                    >------此評論系統生成 無法回复</span
                  >
                </div>

                <div class="commentBox" style="border-bottom: none">
                  <div
                    class="commentInfo"
                    v-if="item.Info && item.Info.length > 0"
                    style="margin-bottom: 10px"
                  >
                    <ImgDecypt
                      class="avatar"
                      round
                      :src="item.Info[0].userPortrait"
                      :key="item.Info[0].userPortrait"
                    />
                    <div class="childInfo">
                      <div class="name">
                        <span>{{ item.Info[0].userName }}</span>
<!--                        <svg-icon-->
<!--                          :iconClass="-->
<!--                            item.Info[0].gender == 'male' ? 'male' : 'female'-->
<!--                          "-->
<!--                        ></svg-icon>-->
                        <svg-icon
                          v-if="
                            item.Info[0].toUserID != 0 &&
                            item.Info[0].toUserID != null
                          "
                          class="rightCommIcon"
                          iconClass="rightCommIcon"
                        ></svg-icon>
                        <span v-if="item.Info[0].toUserID">{{
                          item.Info[0].toUserName
                        }}</span>
                      </div>
                      <div
                        class="text msgContent"
                        v-if="!item.Info[0].isDelete"
                      >
                        <div style="width: calc(100% - 22px)">
                            <CommentJump
                                class="commit_content"
                                :text="item.Info[0].content"
                                :keyword="item.Info[0].searchKeyword"
                                :linkType="item.Info[0].linkType"
                                :linkStr="item.Info[0].linkStr"
                            />
                        </div>
                      </div>
                      <div
                        class="text"
                        style="color: rgb(153, 153, 153)"
                        v-else
                      >
                        内容已被删除
                      </div>
                      <div class="address">
                        <div class="createdAt">
                          <span>{{ item.Info[0].createdAt | time }}</span>
                        </div>

                        <div class="btnBox" v-if="!item.Info[0].linkType">
                          <span @click.stop="reply(item.Info[0], item)">
<!--                            <svg-icon-->
<!--                              class="community_info"-->
<!--                              iconClass="community_info"-->
<!--                            />-->
                              <div class="community_info"></div>
                          </span>

                          <span @click.stop="clickZan(item.Info[0])">
                            <div class="postLikeedIcon" v-if="item.Info[0].isLike"></div>
                            <div v-else class="postLikeIcon"></div>
<!--                            <svg-icon-->
<!--                              class="like icon"-->
<!--                              v-if="item.Info[0].isLike"-->
<!--                              iconClass="postLikeedIcon"-->
<!--                            />-->
<!--                            <svg-icon-->
<!--                              class="like icon"-->
<!--                              v-else-->
<!--                              iconClass="postLikeIcon"-->
<!--                            />-->
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="item.isShow">
                    <div
                      class="commentInfo"
                      v-for="subComment in item.Info.slice(1)"
                      :key="subComment.id"
                      style="margin-bottom: 10px"
                    >
                      <ImgDecypt
                        class="avatar"
                        round
                        :src="subComment.userPortrait"
                        :key="item.userPortrait"
                      />
                      <div class="childInfo">
                        <div class="name">
                          <span>{{ subComment.userName }}</span>
<!--                          <svg-icon-->
<!--                            :iconClass="-->
<!--                              subComment.gender == 'male' ? 'male' : 'female'-->
<!--                            "-->
<!--                          ></svg-icon>-->
                          <svg-icon
                            v-if="
                              subComment.toUserID != 0 &&
                              subComment.toUserID != null
                            "
                            class="rightCommIcon"
                            iconClass="rightCommIcon"
                          ></svg-icon>
                          <span
                            v-if="
                              subComment.toUserID != 0 &&
                              subComment.toUserID != null
                            "
                            >{{ subComment.toUserName }}</span
                          >
                        </div>
                        <div
                          class="text msgContent"
                          v-if="!subComment.isDelete"
                        >
                          <div style="width: calc(100% - 22px)">
                              <CommentJump
                                  class="commit_content"
                                  :text="subComment.content"
                                  :keyword="subComment.searchKeyword"
                                  :linkType="subComment.linkType"
                                  :linkStr="subComment.linkStr"
                              />
                          </div>
                        </div>
                        <div
                          class="text"
                          style="color: rgb(153, 153, 153)"
                          v-else
                        >
                          内容已被删除
                        </div>
                        <div class="address">
                          <div class="createdAt">
                            <span>{{ subComment.createdAt | time }}</span>
                          </div>

                          <div class="btnBox" v-if="!subComment.linkType">
                            <span @click.stop="reply(subComment, item)">
<!--                              <svg-icon-->
<!--                                class="community_info"-->
<!--                                iconClass="community_info"-->
<!--                              />-->
                              <div class="community_info"></div>
                            </span>

                            <span @click.stop="clickZan(subComment)">
                               <div class="postLikeedIcon" v-if="subComment.isLike"></div>
                               <div v-else class="postLikeIcon"></div>
<!--                              <svg-icon-->
<!--                                class="like icon"-->
<!--                                v-if="subComment.isLike"-->
<!--                                iconClass="postLikeedIcon"-->
<!--                              />-->
<!--                              <svg-icon-->
<!--                                class="like icon"-->
<!--                                v-else-->
<!--                                iconClass="postLikeIcon"-->
<!--                              />-->
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Loading
                    class="sec-loading"
                    v-if="
                      commentParams.currentFirstItem.id == item.id &&
                      commentParams.currentLoading
                    "
                  />
                  <div
                    class="load-more"
                    v-else-if="
                      !commentParams.currentLoading &&
                      commentParams.currentFirstItem.id == item.id &&
                      commentParams.currentHasNext
                    "
                    @click.stop="loadMoreSubComment(item)"
                  >
                    — 点击加载更多评论
                  </div>
                </div>
              </div>
              <img
                class="godMsg"
                v-if="item.isGodComment"
                src="@/assets/png/godMsg.png"
              />
            </div>
            <div
              class="expand-box"
              @click.stop="onShow(item)"
              v-if="item.commCount && item.commCount > 1"
            >
              {{ item.isShow ? "— 收起" : `— 展开${item.commCount - 1}条回复` }}
              <svg-icon
                iconClass="expand"
                :class="[
                  { 'rotate-target': item.isShow },
                  { 'rotate-burden-target': !item.isShow },
                ]"
              />
            </div>
          </div>
          <!--                    <div class="msgItem" v-for="item in list" :key="item.id">-->
          <!--                        <div class="msgTopBox">-->
          <!--                            <div @click="jumpUserPage(item)">-->
          <!--                                <ImgDecypt class="avatar" :src="item.userPortrait" :key="item.userPortrait" round />-->
          <!--                            </div>-->
          <!--                            <div class="infoBox">-->
          <!--                                <div class="uName" @click.stop="replyMsg(item)">{{item.userName}}</div>-->
          <!--                                <div class="dateInfo">{{item.createdAt | commentTime}}</div>-->
          <!--                            </div>-->
          <!--                            <div class="likeBox" @click.stop="likeComment(item)">-->
          <!--                                <svg-icon class="likeIcon" :iconClass="item.isLike ? 'redHeart' : 'grey_heart'" />-->
          <!--                                <div>{{item.likeCount}}</div>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                        <div class="msgContent">{{item.content}}</div>-->
          <!--                        <div class="msgLevel2" v-if="item.Info && item.Info.length > 0">-->
          <!--                            <div class="msgBoxLv2" v-for="i in item.Info" :key="i.id">-->
          <!--                                <div class="nameLv2">{{i.userName}}</div>-->
          <!--                                <div class="msgLv2">{{i.content}}</div>-->
          <!--                            </div>-->
          <!--                            <div class="msgNum" @click.stop="jumpDetails(item)">共{{item.commCount || 0}}条回复></div>-->
          <!--                        </div>-->
          <!--                    </div>-->
        </PullRefresh>
      </div>

      <div class="defaultInput">
        <div class="inputBox">
          <van-field
            ref="commentInputBoxRef"
            class="newInput"
            v-model="commentParams.content"
            :border="false"
            :placeholder="placeholder"
            @blur="changBlur"
          />
          <div
            class="sendBtn"
            :class="{ activeBtn: msgVal }"
            @mousedown="handleMousedown"
          ></div>
        </div>
        <!--                <div class="topBox">-->
        <!--                    <div class="defaultText">自古评论出人才</div>-->
        <!--                    <div>-->
        <!--                        <svg-icon class="editIcon" iconClass="editIcon" />-->
        <!--                    </div>-->
        <!--                </div>-->
      </div>
    </van-popup>

    <!--        <van-popup class="inputPop" v-model="isShowInput" position="bottom" @close="closeInput">-->
    <!--            <div class="inputBox">-->
    <!--                <van-field class="newInput" ref="newInput" v-model="msgVal" :border="false"-->
    <!--                           :placeholder="placeholderText"/>-->
    <!--                <div class="sendBtn" :class="{activeBtn: msgVal}" @click="sendMsg">发送</div>-->
    <!--            </div>-->
    <!--        </van-popup>-->
  </div>
</template>

<script>
import { Popup, Field, Toast } from "vant";
import {
  queryComment,
  getSecCommentList,
  sendComment,
  thumbsUpComment,
  thumbsDownComment,
} from "@/api/mine";
import { getBeijinTime } from "@/utils";
import { mapGetters } from "vuex";
import ImgDecypt from "@/components/ImgDecypt";
import PullRefresh from "@/components/PullRefresh";
import Loading from "@/components/Loading";
import CommentJump from "@/components/CommentJump";

export default {
  inject: ["reload"],
  components: {
    ImgDecypt,
    PullRefresh,
    [Field.name]: Field,
    [Popup.name]: Popup,
    Loading,
      CommentJump
  },
  computed: {
    ...mapGetters({
      commentPopDate: "commentPopDate",
      userInfo: "userInfo",
      isVip: "isVip",
    }),
  },
  data() {
    return {
      show: true,
      isShowInput: false,
      msgVal: "",
      placeholder: "我怀疑你也想开车",
      list: [],
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
      msgTotle: "",
      objID: "",
      level: 1,
      cid: undefined,
      userId: undefined,
      currMsgItem: "",
      commentParams: {
        objID: "", // 帖子ID,
        level: 1, // 评论级别，1: 一级评论;2: 二级评论
        content: "", // 评论内容
        cid: "", // 一级评论的ID
        userID: "", // 一级评论的用户ID
        currentFirstItem: {}, // 当前一级评论对象
        currentHasNext: false, // 当前评论是否还有下一页数据
        currentLoading: false, // 当前二级评论的loading
        currentShowInputId: "", // 当前显示的输入框id
        rid: "",
        toUserID: "",
      }, // 评论参数
      secPageNumber: 1, // 二级评论页码
      secPageSize: 10, // 二级评论一页的数量
    };
  },
  created() {
    this.objID = this.commentPopDate.info.id;
    console.log(this.objID);
    this.queryComment();
  },
  methods: {
    openPersonal(item) {
      this.$router.push({ path: "/userHomePage", query: { uid: item.userID } });
      this.$emit("close");
    },
    async onShow(comment) {
      /**
       * 展开与收起
       */
      if (comment.isShow) {
        this.$nextTick(() => {
          comment.isShow = false;
          this.$forceUpdate();
        });
      } else {
        this.commentParams.currentHasNext = true; // 初始化当前评论默认有下一页
        this.commentParams.currentFirstItem = comment;
        this.commentParams.currentLoading = true;
        this.secPageNumber = 1;
        comment.isShow = true;
        await this.getSecondList(comment);
      }
    },
    async loadMoreSubComment(comment) {
      /**
       * 获取更多二级评论
       * @type {boolean}
       */
      this.commentParams.currentLoading = true;
      this.secPageNumber++;
      await this.getSecondList(comment);
    },
    reply(item, parentItem) {
      /**
       * 二级评论输入框显示事件
       */
      if (parentItem != null) {
        this.commentParams.currentFirstItem = parentItem;
        this.commentParams.rid = item.id;
        this.commentParams.toUserID = item.userID;
        this.commentParams.cid = parentItem.id;
      } else {
        this.commentParams.currentFirstItem = item;
        this.commentParams.rid = "";
        this.commentParams.toUserID = "";
        this.commentParams.cid = item.id;
      }
      this.commentParams.objID = item.objID;
      this.commentParams.userID = item.userID;
      this.commentParams.level = 2;
      this.placeholder = `回复@${item.userName}`;
      this.$refs.commentInputBoxRef.focus();
    },
    jumpUserPage(item) {
      // console.log(this.$route.path)
      // if (this.$route.path == "/userHomePage") {
      //     this.$router.replace({path: "/userHomePage", query:{
      //         uid: item.userID
      //     }})
      // } else {
      //     this.$router.push({path: "/userHomePage", query:{
      //         uid: item.userID
      //     }})
      // }
      this.$router.replace({
        path: "/userHomePage",
        query: {
          uid: item.userID,
        },
      });
      this.$store.commit("user/SET_COMMENTPOP", {
        show: false,
        info: {},
      });
      if (this.$route.path == "/userHomePage") {
        this.reload();
      }

      // console.log(item)
    },
    async queryComment(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        objID: this.objID,
        curTime: getBeijinTime(),
      };

      try {
        let res = await this.$Api(queryComment, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          // console.log(list)
         // this.msgTotle = res.data.total;
          this.msgTotle = res.data.lfCount;
          // list.forEach(i => {
          //     i.isFollow = true;
          // });
          if (type === "refresh") {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    showInput() {
      let _this = this;

      /**
       * 加入了判断是否VIP
       */
      if (this.userInfo.isVip && this.userInfo.vipLevel) {
        this.level = 1;
        this.isShowInput = true;
        this.$nextTick(() => {
          if (this.$refs.newInput) {
            this.$refs.newInput.focus();
          }
        });
      } else {
        _this.$bus.$emit("vipPopup", {
          state: 1,
          closeBtn: () => {
            _this.$bus.$emit("closeVipPopup");
          },
        });
      }
    },
    onLoad() {
      // console.log("加载更多")
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.queryComment();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.queryComment("refresh");
    },
    // 回复-显示输入框
    replyMsg(item) {
      this.placeholderText = `回复 @${item.userName}`;
      this.cid = item.id;
      this.userId = item.userId;
      this.level = 2;
      this.isShowInput = true;
      this.currMsgItem = item;
      this.$nextTick(() => {
        if (this.$refs.newInput) {
          this.$refs.newInput.focus();
        }
      });
    },
   changBlur() {
    window.scroll(0, 0); //失焦后强制让页面归位
   },// 发送时阻止ios默认事件
   async handleMousedown(event) {
    event.preventDefault();
    this.$refs.commentInputBoxRef.blur();
    await this.sendMsg();
   },
    async sendMsg() {
      if (!this.commentParams.content) {
        this.$toast("请输入评论");
        return;
      }
      let req = {};
      if (this.commentParams.level == 1) {
        // 一级评论参数
        req = {
          objID: this.objID,
          level: 1,
          content: this.commentParams.content,
        };
      } else {
        // 二级评论参数
        if (this.commentParams.toUserID) {
          req = {
            objID: this.objID,
            level: 2,
            content: this.commentParams.content,
            cid: this.commentParams.cid,
            userID: this.commentParams.userID,
            rid: this.commentParams.rid,
            toUserID: this.commentParams.toUserID,
          };
        } else {
          req = {
            objID: this.objID,
            level: 2,
            content: this.commentParams.content,
            cid: this.commentParams.cid,
            userID: this.commentParams.userID,
          };
        }
      }
      this.$store.commit("app/SET_LOADING", true);
      try {
        let res = await this.$Api(sendComment, req);
        this.$store.commit("app/SET_LOADING", false);
        // console.log(res);
        if (res.code == 200) {
          this.commentParams.content = ""; // 评论输入框内容清空
          // this.lfCount++; // 评论+1
          // if (this.commentParams.level == 1) {
          //   this.isNoData = false;
          //   this.list.unshift(res.data);
          // } else if (this.commentParams.level == 2) {
          //   this.list = this.list.map((item) => {
          //     if (item.id == this.commentParams.currentFirstItem.id) {
          //       item.isShow = true;
          //       item.Info.unshift(res.data);
          //     }
          //     return item;
          //   });
          // }
          this.clearReply();
          this.$toast('评论成功，等待审核');
        } else {
          this.$toast(res.tip);
        }
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("评论失败，请稍后再试");
      }
    },
    clearReply() {
      this.commentParams.currentFirstItem = {};
      this.commentParams.objID = "";
      this.commentParams.cid = "";
      this.commentParams.userID = "";
      this.commentParams.level = 1;
      this.placeholder = `我怀疑你也想开车`;
    },
    closeInput() {
      this.cid = undefined;
      this.userId = undefined;
      this.msgVal = "";
      this.level = 1;
    },
    commentClose() {
      this.$store.commit("user/SET_COMMENTPOP", {
        show: false,
        info: {},
      });
    },
    async clickZan(item) {
      let req = {
        objID: item.id,
        type: "comment",
      };
      this.$store.commit("app/SET_LOADING", true);
      try {
        let res = await this.$Api(
          item.isLike ? thumbsDownComment : thumbsUpComment,
          req
        );
        this.$store.commit("app/SET_LOADING", false);
        if (res.code === 200) {
          if (item.isLike) {
            item.likeCount = item.likeCount - 1 || 0;
          } else {
            item.likeCount = item.likeCount + 1;
          }
          item.isLike = !item.isLike;

          Toast(item.isLike ? "点赞成功" : "取消点赞");
        } else {
          Toast(res.tip || "操作失败，请稍后再试");
        }
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("操作失败，请稍后再试");
      }
    },
    async getSecondList(comment) {
      /**
       * 获取二级评论
       */
      let req = {
        objID: comment.objID,
        cmtId: comment.id,
        fstID: comment.Info[0].id, // 第一条评论的ID
        curTime: new Date().toISOString(),
        pageNumber: this.secPageNumber,
        pageSize: this.secPageSize,
      };

      try {
        let res = await this.$Api(getSecCommentList, req);
        this.commentParams.currentLoading = false;
        if (res.data.list && res.data.list.length > 0) {
          let commentNo = [comment.Info[0]];
          if (this.secPageNumber == 1) {
            comment.Info = commentNo.concat(res.data.list);
          } else {
            comment.Info = commentNo.concat(comment.Info.concat(res.data.list));
          }
        }
        if (!res.data.hasNext) {
          this.commentParams.currentHasNext = false;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    jumpDetails(item) {
      this.$router.push({
        path: "/commentDetails",
        query: {
          msgInfo: this.encodeBase64(encodeURI(JSON.stringify(item))),
        },
      });
      this.commentClose();
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, "utf-8").toString("base64");
      return base64Str;
    },
  },
};
</script>

<style lang="scss" scoped>
.commentPop {
  height: 100%;
  color: #000;

  // position: relative;
  .comment_pop {
    // position: relative;
    // /deep/ .van-popup {
    height: 60%;
    background: #FFFFFF;
    overflow: hidden;
    // }
  }

  .commentBox {
    margin-top: 15px;
    border-bottom: solid rgb(229, 229, 229) 1px;


    .postLikeedIcon{
      background: url("../../assets/png/postLikeedIcon.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 16px;
      height: 16px;
    }

    .postLikeIcon{
      background: url("../../assets/png/postLikeIcon.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 16px;
      height: 16px;
    }

    .commentInfo {
      display: flex;
      width: 100%;

      .godMsg {
        position: absolute;
        right: 55.8px;
        width: 60.2px;
        height: 60.2px;
        z-index: 10;
      }

      .avatar {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-right: 12px;
        flex-shrink: 0;
      }

      .info {
        width: calc(100vw - 100px);
        flex-shrink: 0;
        color: rgb(51, 51, 51);

        .name {
          color: rgb(51, 51, 51);
          font-size: 12px;

          span {
            margin-right: 4px;
          }

          svg {
            width: 10px;
            height: 10px;
          }
        }

        .msgContent {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .zanBox {
            position: absolute;
            right: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 20;

            .zanIcon {
              height: 22px;
              width: 22px;
              margin-bottom: 5px;
            }

            .zanNumber {
              font-size: 12px;
            }
          }
        }

        .text {
          //width: 300px;
          //text-overflow: ellipsis;
          //word-wrap: break-word;
          //white-space: pre-wrap;

          //width: 100%;
          //overflow: hidden;
          white-space: pre-wrap;
          word-wrap: break-word;
          text-overflow: ellipsis;

          font-size: 14px;
          //margin: 4px 0 7px;
          margin-top: 5px;
          color: rgb(51, 51, 51);
        }

        .address {
          //font-size: 10px;
          //color: rgb(153, 153, 153);
          margin-top: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .createdAt {
            font-size: 12px;
            color: rgb(153, 153, 153);
          }

          //.btn {
          //  font-size: 10px;
          //  color: rgb(255, 255, 255);
          //  margin-left: 6px;
          //}

          .btnBox {
            display: flex;
            align-items: center;

            .icon {
              width: 20px;
              height: 20px;
            }

            .community_info {
              background: url("../../assets/png/greyMessage.png");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              width: 20px;
              height: 20px;
              margin-right: 16px;
            }

            .like {
              margin-left: 18px;
            }
          }
        }
      }

      .childInfo {
        width: calc(100vw - 150px);
        flex-shrink: 0;
        color: rgb(102, 102, 102);

        .name {
          color: rgb(153, 153, 153);
          font-size: 12px;
          display: flex;
          align-items: center;

          span {
            margin-right: 4px;
          }

          svg {
            width: 10px;
            height: 10px;
          }

          .rightCommIcon {
            width: 14px;
            height: 14px;
            margin-left: 8px;
            margin-right: 8px;
          }
        }

        .msgContent {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .zanBox {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 20;

            .zanIcon {
              height: 22px;
              width: 22px;
              margin-bottom: 5px;
            }

            .zanNumber {
              font-size: 12px;
            }
          }
        }

        .text {
          //width: 300px;
          //text-overflow: ellipsis;
          //word-wrap: break-word;
          //white-space: pre-wrap;

          //width: 100%;
          //overflow: hidden;
          white-space: pre-wrap;
          word-wrap: break-word;
          text-overflow: ellipsis;

          font-size: 14px;
          //margin: 4px 0 7px;
          margin-top: 5px;
          color: rgb(51, 51, 51);
        }

        .address {
          font-size: 10px;
          color: rgb(153, 153, 153);
          margin-top: 8px;

          .btn {
            font-size: 10px;
            color: rgb(255, 255, 255);
            margin-left: 6px;
          }
        }
      }
    }

    .expand-box {
      font-size: 12px;
      color: rgb(153, 153, 153);
      font-weight: 300;
      margin: 8px 0;
      display: flex;
      align-items: center;

      > svg {
        height: 12px;
        width: 12px;
        margin-left: 5px;
      }
    }

    // 旋转180度
    .rotate-target {
      transform: rotate(180deg);
      transition: transform 0.3s;
    }

    // 旋转0度
    .rotate-burden-target {
      transform: rotate(0deg);
      transition: transform 0.3s;
    }
  }

  .headBox {
    margin-top: 18px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .leftItem {
      display: flex;
      align-items: flex-end;

      .title {
        font-size: 18px;
        margin-right: 12px;
      }

      .commentTotle {
        color: #a4a1bd;
        font-size: 12px;
      }
    }

    .closeBtn {
      width: 15px;
      height: 15px;

      .newClose {
        width: 15px;
        height: 15px;
        font-size: 25px;
      }
    }
  }

  .contentBox {
    height: calc(100% - 136px);
    padding: 18px 16px 0;
    box-sizing: border-box;
    overflow-y: auto;

    .msgItem {
      margin-bottom: 26px;

      .msgTopBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;

        .avatar {
          width: 34px;
          height: 34px;
          margin-right: 18px;
        }

        .infoBox {
          flex: 2;
          color: #a09fb1;

          .uName {
            font-size: 15px;
          }

          .dateInfo {
            font-size: 12px;
          }
        }

        .likeBox {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #a09fb1;
          font-size: 12px;

          .likeIcon {
            width: 16.2px;
            height: 14.8px;
            margin-bottom: 1.6px;
          }
        }
      }

      .msgContent {
        margin-left: 52px;
      }

      .msgLevel2 {
        // width: 100%;
        margin: 6px 0 0 52px;
        border-radius: 6px;
        padding: 9px;
        box-sizing: border-box;
        background: rgb(230, 230, 230);
        font-size: 15px;

        .msgBoxLv2 {
          display: flex;
        }

        .nameLv2 {
          // flex: 1;
          color: rgb(0, 0, 0);
          margin-right: 6px;
        }

        .msgLv2 {
          flex: 2;
          color: rgb(153, 153, 153);
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .msgNum {
          font-size: 15px;
          color: #a09fb1;
          margin-top: 6px;
        }
      }
    }

    // background: red;
  }

  .defaultInput {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #ffffff;
    z-index: 2004;
    padding: 0 12px 34px;
    box-sizing: border-box;

    //.topBox {
    //    height: 59px;
    //    display: flex;
    //    align-items: center;
    //    justify-content: space-between;
    //
    //    .editIcon {
    //        width: 18.5px;
    //        height: 18.5px;
    //    }
    //}
    //
    //.defaultText {
    //    font-size: 15px;
    //    color: #999999;
    //}

    /deep/ .van-field__body {
      background: #eeeeee;
      padding: 6px 17px;
      border-radius: 16px;
    }

    .inputBox {
      display: flex;
      align-items: center;

      .sendBtn {
        background: url("../../assets/png/sendMsg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
    }

    .comments {
      height: 59px;
      display: flex;
      justify-content: space-between;
      padding: 19px 24px;
      color: rgb(153, 153, 153);
      //font-weight: bold;
      font-size: 15px;

      img {
        width: 18.2px;
        height: 18.2px;
      }
    }

    .placeholder {
      height: 34px;
    }
  }

  .inputPop {
    .inputBox {
      height: 59px;
      padding: 0 12px;
      box-sizing: border-box;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .newInput {
        width: 273px;
        height: 31px;
        border-radius: 31px;
        padding: 0 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        background: #e6e6e6;

        /deep/ .van-field__control {
          font-size: 12px;
          color: #000;
        }

        ::placeholder {
          color: #999;
        }
      }

      .sendBtn {
        width: 66px;
        height: 33px;
        border-radius: 33px;
        font-size: 15px;
        color: #fff;
        background: #757494;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .activeBtn {
        color: #fff;
        background: #7145e7;
      }
    }
  }
}
</style>
