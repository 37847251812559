const getters = {
    //接口api
    baseUrl: (state) => {
        return state.app?.baseUrl || "";
    },
    //全局loading
    globalLoading: (state) => {
        return state.app?.globalLoading || false;
    },
    //用户UID
    UID: (state) => {
        return state.app?.UID || "";
    },
    //是否vip
    isVip: (state) => {
        let userInfo = state.user?.userInfo
        if (typeof userInfo == 'string') {
            userInfo = JSON.parse(state.user.userInfo);
        }
        return userInfo.isVip && userInfo?.vipLevel > 0 || false
    },
    //缓存组件列表
    keepAliveList: (state) => {
        return state.app.keepAliveList;
    },
    homeRefreshDisable: (state) => {
        return state.app.homeRefreshDisable;
    },
    //用户信息
    userInfo: state => {
        let userInfo = state.user?.userInfo
        if (typeof userInfo == 'string') {
            userInfo = JSON.parse(state.user.userInfo);
        }
        return userInfo
    },
    // 我的消息
    msgHasNew: state => {
        return state.user.msgHasNew;
    },
    //区号
    areaCode: state => {
        let areaCode = state.user?.areaCode
        return areaCode
    },
    // 选择的线路
    videoRoadLine: (state) => {
        return state.video.videoRoadLine;
    },
    //配置信息
    appConfig: state => {
        let appConfig = state.app?.appConfig
        if (typeof appConfig == 'string') {
            appConfig = JSON.parse(state.app.appConfig);
        }
        return appConfig
    },marqueeList: state =>{
        let marqueeList = state.app?.marqueeList;
        if (typeof marqueeList == 'string') {
            marqueeList = JSON.parse(state.app.marqueeList);
        }
        return marqueeList;
    },
    //钱包信息
    walletInfo: state => {
        return state.user?.walletInfo
    },
    //imgapi
    imgApi: (state) => {
        let appConfig = state.app?.appConfig;
        if (typeof appConfig === "string") {
            appConfig = JSON.parse(state.app?.appConfig);
        }
        let imgDomainList = appConfig?.sourceList?.filter(item => item.type === "IMAGE");
        if (imgDomainList.length > 0) {
            return imgDomainList[0]?.domain[0]?.url;
        }
        return "";
    },
    //视频api
    videoApi: (state) => {
        let appConfig = state.app?.appConfig;
        if (typeof appConfig === "string") {
            appConfig = JSON.parse(state.app?.appConfig);
        }
        let imgDomainList = appConfig?.sourceList?.filter(item => item.type === "VID");
        if (imgDomainList.length > 0) {
            return imgDomainList[0]?.domain[0]?.url;
        }
        return "";
    },
    //打赏弹窗信息
    rewarDate: (state) => {
        return state.user.rewarDate
    },
    //评论弹窗信息
    commentPopDate: (state) => {
        return state.user.commentPopDate
    },
    //获取当前作品
    currentWorks: (state) =>{
        let currentWorks = state.video?.currentWorks
        if (typeof currentWorks == 'string') {
            currentWorks = JSON.parse(state.video.currentWorks);
        }
        return currentWorks;
    },
    //发布已选标签
    tags: (state) => {
        return state.commnity.tags;
    },
    bankCard: (state) => {
        return state.app.bankCard;
    },
    getShowBackToTop: (state) => {
        return state.movies.showBackToTop;
    }
}

export default getters;
