import store from "@/store";
import { adClickReport, dataAdd } from "@/api/app";
import { Api } from "@/utils/index";
import { getLocalItem, getSessionItem } from "@/utils/longStorage";
import route from "@/router/index.js";
import getters from "@/store/getters";

export const AdType = {
  ATP_Launch: 0, /// 启动页
  COMMUNITY_RECOMMEND: 1, //社区推荐
  MOVIES_DOMESTIC: 2, //影视
  COMMUNITY_DETAILS: 3, ///社区列表
  COMMUNITY_POST: 4, ///圈子
  MESSAGE_MODULE: 5, ///消息模块
  CREATION_CENTER: 6, ///创作中心
  VIDEO_ADV: 7, ///视频广告
  FREE_VIDEO: 8, ///免费专区
  COLUMN_ADV: 9, ///专栏广告
  TIPIC_MORE: 10, // 首页专题-更多
  COMMUNITY_ADV: 11, //圈子广告
  CLASSIFICARION: 12, //热门分类
  TIPIC_MORE_VERTICLE: 13, //首页专题-更多横版
  CLASSIFICARION_VERTICLE: 14, //热门分类横版
  FLOATING_ADV: 15, //首页浮动广告
  HOMEADV: 28, //首页广告
  VIDEODETAILROWADV: 29, //视频详情页广告
  NINEGRID_SWIPER: 301, //九宮格輪播廣告
  RECGAME: 201, // 娱乐游戏广告
  RECAPP: 200, // 娱乐游戏广告
  RECYP: 202, // 娱乐约炮广告
  RECQP: 203, // 娱乐棋牌广告
  RECZB: 204, // 娱乐直播广告
};
export const MarqueeType = {
  community: 1, //社区
  movie: 2, //影视
};

/**
 * 获取广告
 * @param {*} type  广告类型
 * @returns   广告数据
 */
export function getAdItem(type) {
  let appConfig = store.state.app.appConfig;
  if (typeof appConfig === "string") {
    appConfig = JSON.parse(store.state.app.appConfig);
  }
  if (
    type == undefined ||
    !appConfig ||
    !appConfig.ads ||
    !appConfig.ads.adsInfoList ||
    appConfig.ads.adsInfoList.length == 0
  )
    return [];
  let advData = appConfig.ads.adsInfoList.filter((item) => {
    if (item.position == type) {
      return item;
    }
  });
  if (!advData || advData.length == 0) return [];
  return advData;
}

/**
 * 获取模块id
 * @param subName
 * @returns {string|null|*}
 */
export function getModuleId(subName) {
  let moduleList = store.state.movies.moduleList;
  if (moduleList.length == 0) {
    return null;
  }
  let list = moduleList.filter((el) => el.subModuleName == subName);
  if (list.length > 0) {
    return list[0].id;
  }
  return "";
}

/**
 * 获取文字公告
 */
export function getAnnouncementText() {
  let appConfig = store.state.app.appConfig;
  if (typeof appConfig === "string") {
    appConfig = JSON.parse(store.state.app.appConfig);
  }
  if (
    !appConfig ||
    !appConfig.ads ||
    !appConfig.ads.announList ||
    appConfig.ads.announList.length == 0
  )
    return [];
  let announList = appConfig.ads.announList.filter((item) => {
    if (item.type == 0) {
      return item;
    }
  });
  if (!announList || announList.length == 0) return [];
  return announList;
}

/**
 * 获取九宫格广告歌
 */
export function getOfficialConfigApps() {
  let appConfig = store.state.app.appConfig;
  if (typeof appConfig === "string") {
    appConfig = JSON.parse(store.state.app.appConfig);
  }
  if (
    !appConfig ||
    !appConfig.officialConfigApps ||
    appConfig.officialConfigApps.length == 0
  )
    return [];
  return appConfig.officialConfigApps;
}

/**
 * 获取楼凤url
 */
export function getLoufengUrl() {
  let appConfig = store.state.app.appConfig;
  if (typeof appConfig === "string") {
    appConfig = JSON.parse(store.state.app.appConfig);
  }
  if (!appConfig || !appConfig.louFengH5) return null;
  return appConfig.louFengH5;
}

/**
 * 获取用户信息
 */
export function getUserInfo() {
  let userInfo = store.state.user.userInfo;
  if (typeof userInfo === "string") {
    userInfo = JSON.parse(store.state.user.userInfo);
  }
  if (!userInfo) return null;
  return userInfo;
}

/**
 * 获取数据埋点接口地址
 */
export function getDataUrl() {
  let appConfig = store.state.app.appConfig;
  if (typeof appConfig === "string") {
    appConfig = JSON.parse(store.state.app.appConfig);
  }
  if (!appConfig || !appConfig.dataBuriedPoint) return null;
  return appConfig.dataBuriedPoint;
}

/**
 * 获取VIP特权
 */
export function getVipProductBenefits() {
  let appConfig = store.state.app.appConfig;
  if (typeof appConfig === "string") {
    appConfig = JSON.parse(store.state.app.appConfig);
  }
  if (
    !appConfig ||
    !appConfig.productBenefits ||
    appConfig.productBenefits.length == 0
  )
    return [];
  return appConfig.productBenefits;
}

/**
 * 获取跑马灯
 */
export function getMarquee(type) {
  let list = store.getters.marqueeList || [];
  if (list.length === 0) return "";
  let n = list.filter((e) => e.position == type);
  if (!n || n.length == 0) return "";
  var str = "";
  for (let i = 0; i < n.length; i++) {
    if (n.length > 1) {
      str += `${n[i].content}\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`;
    } else {
      str += `${n[i].content}`;
    }
  }
  return str;
}

/**
 * 获取奖章
 */
export function getMedal(types) {
  // console.log(types)
  let newList = [];
  let list = store.getters.appConfig.userAwards || [];
  if (list.length === 0 || types?.length === 0) return newList;
  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < types?.length; j++) {
      if (types[j].number == list[i].number) {
        list[i].isExpire = types[j].isExpire;
        newList.push(list[i]);
      }
    }
  }
  // console.log(newList)
  return newList;
}

/**
 * 截取URL参数
 * @param name 需要截取url的参数
 * @returns
 */
function getUrlParam(url, name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  var r = url.substr(1).match(reg); //匹配目标参数

  if (r != null) return unescape(r[2]);
  return null; //返回参数值
}

/**
 * 活动跳转
 * @param {*} item
 */
function Activity(url) {
  let murl = "https://" + url;
  if (murl.indexOf("token") != -1) {
    let token = getLocalItem("token") || "";
    murl = murl.replace(/token=/, `token=${token}`);
  }
  if (murl.indexOf("apihost") != -1) {
    let baseUrl = getSessionItem("baseUrl").replace(/\/api\/h5\//, "");
    murl = murl.replace(/apihost=/, `apihost=${baseUrl}`);
  }
  location = murl;
}

/**
 * 内部跳转
 * @param {*} url  评论内部跳转的url
 */
export function innerJump2(url) {
    let urlIndex = url.indexOf("?");
    let uri = url;
    if (urlIndex != -1) {
        uri = url.substring(0, urlIndex);
    }
    // 金豆充值
    const routeMap = {
        "topic_page": '/specialTopic', //专题
        "welfare_page": '/welfareCenter', //福利
        "hot_ranking_page": '/hotRankingPage', //热榜
        "vip_page": '/rechargePage?t=vip', //会员
        "coin_page": '/rechargePage?t=gold', //金豆
        "SigninPage": '/sign', //签到
    }
    // 跳转动漫详情
    if (uri == 'cartoonDetails') {
        let id = getUrlParam(url.substring(urlIndex), "id");
        if (!id) return;
        route.push({path: '/videoDetailsPage', query: {id: id}})
        return;
    }

    // 跳转漫画详情
    if (uri == 'comicsDetails') {
        let id = getUrlParam(url.substring(urlIndex), "id");
        if (!id) return;
        route.push({path: '/novelDetailsPage', query: {id: id}})
        return;
    }

    // 帖子详情
    if (uri == 'postDetails') {
        let id = getUrlParam(url.substring(urlIndex), "id");
        if (!id) return;
        route.push({path: '/postDetails', query: {videoID: id}})
        return;
    }
    let routePath = routeMap[uri]
    if (routePath) {
        route.push(routePath)
        return;
    }
}

/**
 * 内部跳转
 * @param {*} url  内部跳转的url
 */
export function innerJump(url) {
  let urlIndex = url.indexOf("?");
  let uri = url;
  if (uri == "member_centre?initIndex=1") {
    route.push({ path: "rechargePage", query: { t: "gold" } });
    return;
  }
  if (urlIndex != -1) {
    uri = url.substring(0, urlIndex);
  }
  // 金豆充值
  const routeMap = {
    share_promotion: "/sharePromotion", //分享推广
    member_centre: "/rechargePage?t=vip", //会员中心页面
    topic_page: "/specialTopic", //专题
    welfare_page: "/welfareCenter", //福利
    hot_ranking_page: "/hotRankingPage", //热榜
    vip_page: "/rechargePage?t=vip", //会员中心页面
    wallet_page: "/rechargePage?t=gold", //钱包页面
    aiUndress: "/aiUndress", //AI脱衣
    quanzi: "/community", //圈子
    originWorks: "/original", //圈子
    SigninPage: "/sign", //签到
  };
  // 跳转博主
  if (uri == "userHomePage") {
    let uid = getUrlParam(url.substring(urlIndex), "uid");
    if (!uid) return;
    route.push({ path: "/userHomePage", query: { id: uid } });
    return;
  }
  // 跳转视频详情
  if (uri == "horizontalVideo") {
    let id = getUrlParam(url.substring(urlIndex), "id");
    if (!id) return;
    route.push({ path: "/horizontalVideo", query: { id: id } });
    return;
  }
  // 跳转活动详情
  if (uri == "activity") {
    let id = getUrlParam(url.substring(urlIndex), "id");
    if (!id) return;
    route.push({ path: "/welfareDetails", query: { id: id } });
    return;
  }

  // 跳转漫画
  if (uri == "comics") {
    let id = getUrlParam(url.substring(urlIndex), "id");
    if (!id) return;
    route.push({ path: "/novelDetailsPage", query: { id: id } });
    return;
  }
  if (uri == "video") {
    let id = getUrlParam(url.substring(urlIndex), "id");
    if (!id) return;
    route.push({
      path: "/videoDetailsPage",
      query: { id: id, kind: 0, fromTagList: false },
    });
    return;
  }

  let routePath = routeMap[uri];
  if (routePath) {
    route.push(routePath);
    return;
  }
}

/**
 * 跳转广告
 * @param {*} item 广告信息
 */
export function jumpAdv(item) {
  let id = item.id;
  let name = item.name;

  if (id) {
    Api(adClickReport, {
      id: id,
      adName: name,
    });
  }
  let url =
    item.jumpLink ||
    item.jumpTo ||
    item.href ||
    item.officialUrl ||
    item.link_url ||
    item.link;
  let index = url.indexOf("yinseinner");
  let yinssIndex = url.indexOf("yinselinks");
  if (index != -1) {
    innerJump(url.substring(13));
    return;
  }
  if (yinssIndex != -1) {
    Activity(url.substring(13));
    return;
  }
  // location = url
  //外部跳转
  setTimeout(() => {
    window.open(url);
  });
}

/**
 * 获取搜索历史
 */
export function getSearchKeys() {
  let aryKey = [];
  let aryKeyStr = sessionStorage.getItem("searchKeys");
  if (aryKeyStr != null) {
    aryKey = JSON.parse(aryKeyStr);
  }
  return aryKey;
}

/**
 * 存储搜索记录
 */
export function setSearchKey(searchValue) {
  let aryKey = getSearchKeys();
  if (aryKey.length > 10) {
    aryKey.pop();
  }
  aryKey.unshift(searchValue);
  sessionStorage.setItem("searchKeys", JSON.stringify(aryKey));
}

/**
 * 删除指定搜索历史
 */
export function delSearchKey(index) {
  let aryKey = getSearchKeys();
  aryKey.splice(index, 1);
  sessionStorage.setItem("searchKeys", JSON.stringify(aryKey));
}

/**
 * 清除搜索历史
 */
export function cleanSearchKey() {
  sessionStorage.setItem("searchKeys", JSON.stringify([]));
}
